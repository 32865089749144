import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import Information from './pages/Information';
import MainNavbar from './components/navbar/MainNavbar';
import { makeStyles } from '@material-ui/core';
import { useJQuery } from './hooks';
import CustomGrid from './components/CustomGrid';
import ReactDOM from 'react-dom';
import CustomAccordion from './components/CustomAccordion';

const useStyles = makeStyles(() => ({
  root: {
  }
}));

/** */
export default () => {
  const classes = useStyles();
  const { jAction } = useJQuery();

  const [initialUrl, setInitialUrl] = useState<string>('');

  useEffect(() => {
    jAction(() => {
      const linkUrls = ($('#link_urls').val() as string)?.split(';') || [];
      const url = linkUrls[0] + window.location.search;

      setInitialUrl(url);

      const lists = $('.entity-list-custom').find('.list-id').map((i, e) => $(e).val() as string).toArray();
      lists.forEach(l => {
        ReactDOM.render(<CustomGrid id={l} />, document.getElementById(`root-${l}`));
      });

      $(document).ready(() => {
        const accordions = $('.accordion-custom').toArray();
        console.log(`${accordions.length} accordian-custom elements.`);
        accordions.forEach(a => {
          console.log(`Rendering the CustomAccordian for id: ${a.id}`);
          const rootElement = document.getElementById(`root-${a.id}`);
          ReactDOM.render(<CustomAccordion id={a.id} />, rootElement);
        });
      });
    });
  }, [])
  /** */
  return (<>
    <MainNavbar />
    <main id="main-content" className={classes.root}>
      <Switch>
        <Route exact path="/:lang/info" component={Information} />
        <Route exact path="/:lang?" render={() => {
          const pathName = window.location.pathname;
          console.info(`pathname: ${pathName}`);
          console.info(`initialUrl: ${initialUrl}`);

          if (typeof initialUrl !== 'undefined' &&
            initialUrl != null &&
            initialUrl != '' &&
            initialUrl != 'undefined' &&
            window.location.pathname != initialUrl) {
            console.log(`Navigating to: ${initialUrl}`);
            window.location.href = initialUrl ?? '';
          }
          return null;
        }} />
      </Switch>
    </main>
  </>)
}
