import React, { createContext, useContext, useEffect, useState } from 'react';

interface IContext {
  /** Action that depends on JQuery to be available. */
  jAction: (usage: () => any) => Promise<void>;
  ready: boolean;
}

const JQueryContext = createContext<IContext>({
  jAction: () => Promise.resolve(),
  ready: false
});

export const useJQuery = () => {
  const context = useContext(JQueryContext);
  if (context === undefined) {
    throw new Error('useJQuery must be used within a JQueryProvider');
  }
  return context;
}

export const JQueryProvider = ({ children }: { children: JSX.Element }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const jqInterval = setInterval(() => {
      if (typeof $ === 'undefined') return;
      setReady(true);
      clearInterval(jqInterval);
    }, 500);
  }, []);

  const jAction = (usage: () => any) => {
    return new Promise<void>((resolve) => {
      if (!ready) {
        const jqInterval = setInterval(() => {
          if (typeof $ === 'undefined') return;
          clearInterval(jqInterval);
          $(() => {
            usage();
            resolve();
          });
        }, 500);
      } else {
        $(() => {
          usage();
          resolve();
        });
      }
    });
  };
  return (
    <JQueryContext.Provider value={{
      ready,
      jAction
    }}>
      {children}
    </JQueryContext.Provider>
  )
}
