import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { SetNavigation } from '../../actions';
import {
  NavigationBlockLink
} from '@churchofjesuschrist/eden-side-navigation';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  pathActive: {
    backgroundColor: theme.palette.grey[100] // #f5f5f5
  },
  itemActive: {
    boxShadow: `5px 0 0 0 ${theme.palette.primary.light} inset` // border without messing with position
  },
  listItem: {
    padding: 0,
    display: 'block'
  },
  listItemText: {
    cursor: 'pointer',
    float: 'left'
  },
  linkGroup: {
    '&:hover a': {
      borderInlineStartColor: 'transparent'
    }
  },
  itemRipple: {
    color: 'rgb(224,226,226)'
  }
}));

interface IProps {
  text: string;
  route: string;
  icon: React.ReactElement;
  partOfPath?: boolean;
  children?: JSX.Element;
  selected: boolean;
  openNew?: boolean;
  nav: { open: boolean, id: string, path: string }
}

const NavListItem = ({ text, icon, partOfPath, children, route, selected, nav, openNew }: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(partOfPath);
  const dispatch = useDispatch();

  useEffect(() => {
    const navPath = new URL(location.origin + nav?.path).pathname;
    const routePath = new URL(location.origin + route).pathname;
    const routeInPath = navPath === routePath || navPath?.indexOf(routePath) > -1;
    if (open && nav?.open && !routeInPath) {
      console.log(nav.path);
      console.log(route);
      setOpen(false);
    }
  }, [nav]);

  const handleClick = () => {
    setOpen(!open);
    dispatch(SetNavigation(route, !open));
    handleRoute();
  };

  /** Navigate to route if not parent route. */
  const handleRoute = () => {
    if (children) return;

    if (openNew) {
      window.open(route, '_blank');
    } else {
      window.location.assign(route);
    }
  }
  return (<>
    <ListItem button onClick={handleClick} TouchRippleProps={{ classes: { root: classes.itemRipple } }} className={clsx(classes.listItem, {
      [classes.pathActive]: partOfPath,
      [classes.linkGroup]: !!children
    })}>
      <NavigationBlockLink active={selected} icon={icon}>
        <ListItemText onClick={handleRoute} primary={text} className={classes.listItemText} />
        {children ?
          open ? <ExpandLess /> : <ExpandMore /> : ''}
      </NavigationBlockLink>
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit={false} className={clsx({
      [classes.pathActive]: partOfPath
    })}>
      <List component="div" disablePadding className={classes.nested}>
        {children}
      </List>
    </Collapse>
  </>)
}

export default connect((state: any) => ({ nav: state.navigation }))(NavListItem)
