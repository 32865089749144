import React, { useEffect, useState, useMemo } from 'react';
import { useJQuery } from 'src/hooks';
import { DataGrid, GridCellParams, GridColDef, GridFilterModel, GridRowData, GridSortModel } from '@material-ui/data-grid';
import Pagination from '@material-ui/lab/Pagination';
import { IconButton, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

interface IHeader {
  name: string;
  logicalName: string;
  sortEnabled: boolean;
  sort: string;
  width: number;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#006184',
      light: '#01b6d1',
      dark: '#003057'
    }
  },
  typography: {
    fontFamily: 'Zoram,sans-serif'
  }
});

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  grid: {
    minHeight: '70vh'
  },
  columnHeader: {
    background: theme.palette.primary.main,
    color: 'white'
  },
  searchRoot: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
});

const CustomPagination = ({ pages, page, setPage }: { pages: number, page: number, setPage: (page: number) => void }) => {
  const classes = useStyles();

  return (
    <Pagination
      className={classes.root}
      color="primary"
      count={pages}
      page={page}
      onChange={(ev, value) => setPage(value)}
    />
  )
}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: (val: string) => void;
  value: string;
}

const SearchToolbar = ({ onChange, clearSearch, value }: QuickSearchToolbarProps) => {
  const [searchVal, setSearchVal] = useState('');
  const classes = useStyles();

  const submit = () => {
    onChange(searchVal);
  }

  useEffect(() => {
    setSearchVal(value);
  }, [value]);

  return (
    <div className={classes.searchRoot}>
      <div></div>
      <TextField
        variant="standard"
        value={searchVal}
        onChange={(ev) => setSearchVal(ev.target.value)}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <IconButton onClick={submit}><SearchIcon fontSize="small" /></IconButton>,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default ({ id }: { id: string }) => {
  const classes = useStyles();
  const { jAction } = useJQuery();
  const [headers, setHeaders] = useState<IHeader[]>([]);
  const [data, setData] = useState<GridRowData[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [, setFilters] = useState<{ [index: string]: any }>({});
  // TODO
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    try {
      const filter = decodeURIComponent(params.get('filter') || '');
      setFilterModel(JSON.parse(filter));
    } catch (e) {
      console.info(e);
    }
    const order = decodeURIComponent(params.get('order') || '').split('=');
    if (order.length > 1) {
      setSortModel([{ field: order[0], sort: order[1] as any }]);
    }
    const searchParam = params.get('search') || '';
    setSearchText(searchParam);
    const page = params.get('page') || '1';
    setPage(parseInt(page));
    jAction(() => {
      const pages = parseInt($(`#pages-${id}`).val() as string);
      setPages(pages);

      const listHeaders = $(`#list-headers-${id}`).find('.header-container');
      const viewHeaders = listHeaders.map((i, e) => {
        const logicalName = $(e).find('.header-logical-name').val();
        const name = $(e).find(`#header_name_${logicalName}-${id}`).val();
        const sortEnabled = $(e).find(`#sort_enabled_${logicalName}-${id}`).val() === 'true';
        const sort = $(e).find(`#current_sort_${logicalName}-${id}`).val();
        const width = $(e).find(`#width_${logicalName}-${id}`).val() as string;
        return {
          name,
          sortEnabled,
          sort,
          logicalName,
          width: parseInt(width) * 3
        } as IHeader;
      }).toArray();
      setHeaders([...viewHeaders, { logicalName: 'detail-url', width: 100 } as IHeader]);

      const listData = $(`#list-data-${id}`).find('.data-container');
      const viewData = listData.map((i, e) => {
        const detailUrl = $(e).find(`#detail-url-${id}`).val() as string;
        const record = {
          id: i.toString()
        } as { [index: string]: string };
        record['detail-url'] = detailUrl;
        const container = $(e).find('.data-column-container');
        container.each((j, c) => {
          const logicalName = $(c).find('.data-logical-name').val() as string;
          const val = $(c).find(`#data-name-${logicalName}-${id}`).val() as string;
          record[logicalName] = val;
        });
        return record as any;
      }).toArray();
      setData(viewData);

      const listFilters = $(`#list-filters-${id}`).find('.list-filter-container');
      const listFilter: { [index: string]: any } = {};
      listFilters.each((i, e) => {
        const filter = $(e).find('.filter-option');
        filter.each((j, el) => {
          const name = $(el).attr('name');
          const value = $(el).val();
          if (name) {
            if (listFilter[name]) {
              let val = listFilter[name];
              if (typeof val === 'object') {
                val.push(value);
              } else {
                val = [val, value];
              }
              listFilter[name] = val;
            } else {
              listFilter[name] = value;
            }
          }
        });
      });
      setFilters(listFilter);

      // const searchParam = $(`#list-search-${id}`).val() as string;
      // setSearchText(searchParam || '');
    });
  }, []);

  const columns: GridColDef[] = useMemo(() => headers?.map(h => {
    if (h.logicalName === 'detail-url') {
      return {
        renderCell: (params: GridCellParams) => <IconButton color="primary" aria-label="edit" href={params.value?.toString() || ''}>
          <EditIcon />
        </IconButton>,
        width: h.width,
        field: h.logicalName,
        sortable: false,
        filterable: false,
        headerName: 'View'
      }
    }
    return {
      field: h.logicalName, headerName: h.name, width: h.width, sortEnabled: h.sortEnabled, filterable: false
    } as GridColDef
  }) || [], [headers]);

  const setPageNumber = (number: number) => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.delete('page');
    params.append('page', number.toString());
    location.assign(url.toString());
  }

  const handleFilterModelChange = (filterModel: GridFilterModel) => {
    const newFilter = filterModel.items.reduce((p, v, i) => {
      p += `${v.id}=${v.value}`;
      return p;
    }, '');
    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.delete('page');
    params.delete('mf');
    if (newFilter) {
      params.append('mf', encodeURIComponent(newFilter));
    }
    location.assign(url.toString());
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const newSort = sortModel.reduce((p, v, i) => {
      p += `${v.field} ${v.sort}`;
      return p;
    }, '');
    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.delete('order');
    if (newSort) {
      params.append('order', encodeURIComponent(newSort));
    }
    location.assign(url.toString());
  }

  const setSearch = (search: string) => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.delete('search');
    params.delete('page');
    params.delete('mf');
    if (search) {
      params.append('search', search);
    }
    location.assign(url.toString());
  }

  return (
    <ThemeProvider theme={theme}>
      <div id={`custom-grid-${id}`}>
        <DataGrid
          className={classes.grid}
          columns={columns}
          rows={data}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          components={{
            Pagination: CustomPagination,
            Toolbar: SearchToolbar
          }}
          classes={{
            columnHeader: classes.columnHeader
          }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (value) => setSearch(value),
              clearSearch: (ev) => setSearch('')
            },
            pagination: {
              page: page,
              pages: pages,
              setPage: setPageNumber
            }
          }}
        />
      </div>
    </ThemeProvider>
  )
}
