import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import { useJQuery } from './hooks';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  loader: {
    position: 'fixed',
    left: '50%',
    top: '40%',
    zIndex: 100
  },
  loaderContainer: {
    position: 'fixed',
    top: 0,
    zIndex: 2000,
    background: 'white',
    opacity: '.97',
    width: '100%',
    height: '100%'
  },
  hidden: {
    display: 'none'
  }
}));

const App = () => {
  const classes = useStyles();
  const { jAction } = useJQuery();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    jAction(() => {
      let content = $('#liquid_form').length ?
        $('#liquid_form') :
        $('#content-container').length ?
          $('#content-container') :
          $('#content_form');
      if (!content.length) {
        content = $('.container');
      }
      if (content.length) {
        content.prependTo('#main-content'); // move main content into app
        $('#languages-dropdown').prependTo('#profile-container'); // move languages into app
        $('#languages-dropdown').css('display', 'flex');
        content.css('display', 'block');
      }
      $('#PFnavContainer').css('z-index', 'auto');
      setLoading(false);
    });

    document.body.addEventListener('set-loading', setIsLoading);

    return function cleanup() {
      document.body.removeEventListener('set-loading', setIsLoading);
    }
  }, []);

  const setIsLoading = () => {
    setLoading(true);
  }

  return (<>
    <div className={clsx(classes.loaderContainer, {
      [classes.hidden]: !loading
    })}>
      <CircularProgress size={100} className={classes.loader} />
    </div>
    <Router>
      <Switch>
        <Route path="/:lang" render={(props) => <Layout />} />
      </Switch>
    </Router>
  </>);
}

export default App;
