const navigation = (state: { id?: string, path?: string, ready?: boolean, open?: boolean } = {}, action: any) => {
  switch (action.type) {
    case 'SET_NAV':
      const open = action.open;
      const path = action.path;
      return {
        ...state,
        open,
        path
      }
    default:
      return state;
  }
}

export default navigation;
