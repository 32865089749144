export interface IAuthCtx {
  user?: IUser;
  type?: string;
}

export interface IUser {
  id: string;
  fullName: string;
}

const auth = (state: IAuthCtx = {}, action: IAuthCtx) => {
  switch (action.type) {
    case 'SET_USER':
      if (!action?.user) return state;
      const { user: { id, fullName } } = action;
      return {
        ...state,
        user: {
          id,
          fullName
        }
      }
    default:
      return state;
  }
}

export default auth;
